import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TimePicker from "react-time-picker";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import { toast } from "react-toastify";

const TimeSlotEditModal = (props) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState("");

  const timeData = [];

  for (let i = 8; i <= 22; i++) {
    const timeString = i.toString().padStart(2, "0") + ":00";
    const id = i - 7; // Adjusting id to start from 1

    timeData.push({
      id,
      time: timeString,
    });
  }

  // console.log("timedata: ", timeData);

  useEffect(() => {
    if (props?.show) {
      setError("");
      setStartTime(props?.timeslot?.start_time);
      setEndTime(props?.timeslot?.end_time);
      formik.setFieldValue("start_time", props?.timeslot?.start_time);
      formik.setFieldValue("end_time", props?.timeslot?.end_time);
    }
  }, [props?.show]);

  const validationSchema = Yup.object().shape({
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required"),
  });

  const formik = useFormik({
    initialValues: {
      start_time: "",
      end_time: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log("values: ", values);
      const isValidate = validateTimeSelection(
        values.start_time,
        values.end_time
      );
      if (isValidate) {
        console.log("can go further now!");
        const user = JSON.parse(getUser());
        const data = {
          id: props?.timeslot?._id,
          start_time: values?.start_time,
          end_time: values?.end_time,
        };
        fetchDataFromAPI("timeslot", "post", data, user?.authToken)
          .then((res) => {
            toast.success("TimeSlot updated successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            console.log("timeslot update res: ", res?.data);
            props?.onHide();
          })
          .catch((error) => {
            toast.error("Error while updating timeslot", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            console.log("timeslot update error: ", error);
          });
      }
    },
  });

  function validateTimeSelection(start_time, end_time) {
    setError("");
    // Convert time strings to Date objects
    const start_datetime = new Date(`2000-01-01T${start_time}`);
    const end_datetime = new Date(`2000-01-01T${end_time}`);

    // Calculate the duration in milliseconds
    const duration = end_datetime - start_datetime;

    // Check if start time is before end time
    if (start_datetime >= end_datetime) {
      setError("Start time cannot be greater than or equal to End time.");
      return false;
    }

    // Check if the duration is exactly 1 hour (3600000 milliseconds)
    if (duration !== 3600000) {
      setError("Time duration should be one hour only.");
      return false;
    }

    setError("");
    return true;
  }

  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Timeslot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-evenly">
            <div className="d-flex flex-column align-items-center">
              <label htmlFor="startTime" className="form-label">
                Start Time
              </label>
              <select
                className="form-select"
                {...formik.getFieldProps("start_time")}
              >
                {timeData?.map((time, index) => (
                  <option key={time?.id} value={time?.time}>
                    {time?.time}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex flex-column align-items-center">
              <label htmlFor="endTime" className="form-label">
                End Time
              </label>
              <select
                className="form-select"
                {...formik.getFieldProps("end_time")}
              >
                {timeData?.map((time, index) => (
                  <option key={time?.id} value={time?.time}>
                    {time?.time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {error && <div className="text-danger text-center mt-2">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary_bg border-0" onClick={formik.handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TimeSlotEditModal;
