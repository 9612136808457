import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const AdminDetail = () => {
  const { state } = useLocation();
  const { admin } = state;
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [showPassword, setShowPassword] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");
  const navigate = useNavigate();
  const fileInputRef = useRef();

  useEffect(() => {
    getAdminData();
  }, []);

  const getAdminData = () => {
    setLoading(true);
    formik.setFieldValue("first_name", admin?.first_name);
    formik.setFieldValue("last_name", admin?.last_name);
    formik.setFieldValue("email", admin?.email);
    formik.setFieldValue("password", admin?.password);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    password: Yup.string().required("Please enter password"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("user update body: ", values);
      const user = JSON.parse(getUser());
      const data = {
        id: admin?._id,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        profile: url,
      };
      if (!url) {
        delete data.profile;
      }
      console.log("data: ", data);
      fetchDataFromAPI(`admin/profile-update`, "post", data, user?.authToken)
        .then((res) => {
          toast.success("Admin updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          navigate("/admins");
          console.log("update Admin res: ", res?.data);
        })
        .catch((error) => {
          toast.error("Error while updating Admin", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating Admin: ", error);
        });
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  const handleFileChange = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append("image", image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("upload", "post", data, user?.authToken)
      .then((res) => {
        console.log("res: ", res?.data);
        setURL(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="dsa__advice pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">Admin Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={url ? url : admin?.profile}
            className="rounded-circle avatar"
            width={100}
            height={100}
          />
          {isEditable && (
            <div
              className="icon-style disabled"
              onClick={() => isEditable && fileInputRef?.current?.click()}
            >
              <i class="bi bi-pencil-square"></i> Edit
            </div>
          )}
          <input
            ref={fileInputRef}
            accept="image/*"
            className="d-none"
            type="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">First Name</label>
          <input
            type="text"
            name="first_name"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("first_name")}
            // value={formik.values.first_name}
            // onChange={(e) => {
            //   formik.setFieldValue("first_name", e.target.value?.trimStart());
            // }}
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <div className="text-danger">{formik.errors.first_name}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Last Name</label>
          <input
            type="text"
            name="last_name"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("last_name")}
            // value={formik.values.first_name}
            // onChange={(e) => {
            //   formik.setFieldValue("first_name", e.target.value?.trimStart());
            // }}
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <div className="text-danger">{formik.errors.last_name}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Email</label>
          <input
            type="text"
            name="email"
            disabled={true}
            className="form form-control bg-transparent text-black type_password"
            value={formik.values.email}
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              disabled={isEditable ? false : true}
              className="form form-control bg-transparent text-black type_password"
              // {...formik.getFieldProps("password")}
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value?.trim());
              }}
            />
            {isEditable && (
              <div
                className="eye-icon text-secondary"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </div>
            )}
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button btn advice__category"
            disabled={
              buttonValue === "Update" &&
              formik.values.first_name === admin?.first_name &&
              formik.values.last_name === admin?.last_name &&
              formik.values.password === admin?.password &&
              url === ""
            }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDetail;
