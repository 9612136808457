import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getUser } from "../../utils/constants";

const AddLevelModal = (props) => {
  const validationSchema = Yup.object().shape({
    level_name: Yup.string().required("Please enter level name"),
  });

  const formik = useFormik({
    initialValues: {
      level_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
      const user = JSON.parse(getUser());
      fetchDataFromAPI("level", "post", values, user?.authToken)
        .then((res) => {
          toast.success("Level created successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("add level res: ", res?.data);
          props?.onClose();
          resetForm();
        })
        .catch((error) => {
          toast.error("Error while creating Level", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("add level error: ", error);
        });
    },
  });

  const resetForm = () => {
    formik.resetForm();
  };

  return (
    <>
      <Modal
        onHide={() => {
          props?.onHide();
          resetForm();
        }}
        {...props}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Level</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Level Name</label>
            <input
              type="text"
              name="level_name"
              placeholder="Enter level name"
              className="form form-control bg-transparent text-black add_inputs"
              // {...formik.getFieldProps("username")}
              value={formik.values.level_name}
              onChange={(e) => {
                formik.setFieldValue("level_name", e.target.value?.trimStart());
              }}
            />
            {formik.touched.level_name && formik.errors.level_name && (
              <div className="text-danger">{formik.errors.level_name}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            variant="secondary"
            className="btn btn-primary"
            //   data-bs-dismiss="modal"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="btn secondary_bg advice__category text-white"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddLevelModal;
