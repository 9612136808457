import React, { useEffect, useState } from "react";
import bars from "../../assets/svgs/toggle_bars.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import LogOutModal from "../LogoutModal/LogoutModal";
import logo from "../../assets/images/logo.png";
import logoWhite from "../../assets/images/logo-white.png";
import constants from "../../utils/constants";
import sidebarLogo from "../../assets/svgs/logo.svg";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const removeClass = (element) => {
    for (let i = 0; i <= 6; i++) {
      element[i]?.classList.remove("active");
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("dashboard")[0]?.classList.add("active");
    } else if (pathname.includes("/courts")) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("courts")[0]?.classList.add("active");
    } else if (pathname.includes("/members")) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("members")[0]?.classList.add("active");
    } else if (pathname.includes("/admins")) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("admins")[0]?.classList.add("active");
    } else if (pathname.includes("/levels")) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("levels")[0]?.classList.add("active");
    } else if (pathname.includes("/timeslots")) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("timeslots")[0]?.classList.add("active");
    }
  }, [pathname]);

  const onClose = () => {
    navigate("/login");
  };

  return (
    <>
      <aside className="dsa__dashboard">
        <div className="dsa__dashboard-wrapper">
          <div className="dsa__dash-logo d-flex align-items-center">
            <img src={logo} alt="logo" className="w-25 h-25" />
            <p className="text-black fw-bold fs-5 m-0 ms-3">Court Club</p>
          </div>
          <ul className="dsa__dash-menu">
            <li>
              <Link to="/" className="link dashboard gap-3">
                <i className="bi bi-house"></i>
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/courts"
                className="link courts align-items-center gap-3"
              >
                <i className="bi bi-building"></i>
                Courts
              </Link>
            </li>
            <li>
              <Link to="/members" className="link members gap-3">
                <i className="bi bi-people"></i>
                Members
              </Link>
            </li>
            <li>
              <Link to="/admins" className="link admins gap-3">
                <i className="bi bi-person-gear"></i>
                Admins
              </Link>
            </li>
            <li>
              <Link to="/levels" className="link levels gap-3">
                <i className="bi bi-bar-chart"></i>
                Levels
              </Link>
            </li>
            <li>
              <Link to="/timeslots" className="link timeslots gap-3">
                <i className="bi bi-clock"></i>
                TimeSlots
              </Link>
            </li>
            <li>
              <Link
                className="link logout gap-3"
                // type="button"
                data-bs-toggle="modal"
                data-bs-target="#userModal5"
              >
                <i className="bi bi-box-arrow-left"></i>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <LogOutModal onClose={onClose} />

      <div className="dsa__dash-header">
        <div className="dsa__dash-header-wrapper">
          <div className="ra_dash-head_right">
            <div className="dsa__dash-head_profile">
              <Button onClick={handleShow}>
                <img src={bars} alt="toggle-btn" className="bars" />
              </Button>

              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="dsa__dashboard">
                    <div className="dsa__dashboard-wrapper">
                      <div className="dsa__dash-logo">
                        {/* <img src={logo} alt="logo" /> */}
                      </div>
                      <ul className="dsa__dash-menu">
                        <li>
                          <Link
                            to="/"
                            className="link dashboard"
                            onClick={handleClose}
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/courts"
                            className="link courts"
                            onClick={handleClose}
                          >
                            Courts
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/members"
                            className="link members"
                            onClick={handleClose}
                          >
                            Members
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/admins"
                            className="link admins"
                            onClick={handleClose}
                          >
                            Admins
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/levels"
                            className="link levels"
                            onClick={handleClose}
                          >
                            Levels
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/timeslots"
                            className="link timeslots"
                            onClick={handleClose}
                          >
                            TimeSlots
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/login"
                            // type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#userModal5"
                            onClick={() =>
                              localStorage.removeItem(constants.USER)
                            }
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
          <div className="ra_dash-head_left">
            <div className="dsa__dash-head_profile">
              <Link to="">{/* <img src={profile} alt="profile" /> */}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
