import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import AddLevelModal from "../../components/AddLevelModal/AddLevelModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimeSlotEditModal from "../../components/TimeSlotEditModal/TimeSlotEditModal";
import AddTimeSlotModal from "../../components/AddTimeSlotModal/AddTimeSlotModal";

const TimeSlots = () => {
  const [loading, setLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(() => {
    getTimeSlots();
  }, []);

  useEffect(() => {
    if (selectedSlot) {
      setEditModalShow(true);
    }
  }, [selectedSlot]);

  const getTimeSlots = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI("timeslot", "get", "", user?.authToken)
      .then((res) => {
        console.log("timeslot res: ", res?.data);
        setTimeSlots(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("timeslot error: ", error);
      });
  };

  const onDeleteTimeSlot = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI(`timeslot?id=${deleteID}`, "delete", "", user?.authToken)
      .then((res) => {
        toast.success("Timeslot deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getTimeSlots();
        handleClose();
        console.log("Timeslot delete res: ", res?.data);
      })
      .catch((error) => {
        toast.error("Error while deleting Timeslot", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("Timeslot delete error: ", error);
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Timeslots</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary"
            onClick={() => setAddModalShow(true)}
          >
            Add TimeSlot
          </button>
        </div>

        {/* add timeslot modal */}
        <AddTimeSlotModal
          show={addModalShow}
          onHide={() => {
            setAddModalShow(false);
            getTimeSlots();
          }}
        />

        {/* edit timeslot modal */}
        <TimeSlotEditModal
          show={editModalShow}
          onHide={() => {
            getTimeSlots();
            setSelectedSlot(null);
            setEditModalShow(false);
          }}
          timeslot={selectedSlot}
        />
        {/* table here */}
        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th className="text-center">No.</th>
                <th className="text-center">Start Time</th>
                <th className="text-center">End Time</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            <tbody>
              {timeSlots?.length > 0 ? (
                timeSlots?.map((timeslot, index) => (
                  <tr key={timeslot?._id}>
                    <td className="text-center">
                      <p>{index + 1}</p>
                    </td>
                    <td className="text-center align-items-center">
                      <span className="px-0 py-2 badge bg-info">
                        {timeslot?.start_time}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="px-0 py-2 badge bg-info">
                        {timeslot?.end_time}
                      </span>
                    </td>
                    <td className="h-100">
                      <div className="d-flex gap-2 justify-content-center">
                        <span
                          onClick={() => {
                            setSelectedSlot(timeslot);
                            // navigate(`/timeslots/${timeslot?._id}`, {
                            //   state: { timeslot: timeslot },
                            // });
                          }}
                        >
                          <BsFillEyeFill />
                        </span>
                        {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                        <div
                          className="delete_button"
                          onClick={() => {
                            setDeleteID(timeslot?._id);
                            handleShow();
                          }}
                        >
                          <Delete />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete this TimeSlot?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteTimeSlot}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TimeSlots;
