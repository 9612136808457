import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Processing from '../../components/Processing/Processing';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../utils/api';
import {getUser} from '../../utils/constants';
import {toast} from 'react-toastify';
import {io} from 'socket.io-client';
import zero from '../../assets/svgs/zero.svg';
import one from '../../assets/svgs/one.svg';
import two from '../../assets/svgs/two.svg';
import threeToFive from '../../assets/svgs/three-five.svg';
import sixToNine from '../../assets/svgs/six-nine.svg';
import tenPlus from '../../assets/svgs/tenplus.svg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CourtDetail = () => {
  const {state} = useLocation();

  const {court} = state;
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonValue, setButtonValue] = useState('Edit');
  const [file, setFile] = useState(null);
  const [url, setURL] = useState(null);
  const [imgUrl, setImgURL] = useState();
  const [courtData, setCourtData] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const socket = io('https://admin-cc.serenpedia.com:3000/', {
    transports: ['websocket'],
  });

  useEffect(() => {
    getCourtData();

    socket.on('connect', onSocketConnect);

    return () => {
      socket.emit('leaveTimeSlot', {courtId: court?._id});
      socket.off('onTimeSlot', onTimeSlotEvent);
      // socket.disconnect();
    };
  }, []);

  // useEffect(() => {
  //   getAllCourts();
  // }, []);

  // const getAllCourts = async () => {
  //   setLoading(true);
  //   const user = JSON.parse(getUser());
  //   fetchDataFromAPI('Court', 'get', '', user?.authToken)
  //     .then((res) => {
  //       console.log('courts####:', res?.data);
  //     })
  //     .catch((error) => {
  //       console.log('courts error: ', error);
  //     });
  // };

  const onSocketConnect = () => {
    console.log('connected');
    if (socket.connected) {
      socket.on('onTimeSlot', onTimeSlotEvent);
      socket.emit('joinTimeSlot', {
        courtId: court?._id,
      });
    }
  };

  const onTimeSlotEvent = (data) => {
    console.log('is socket connected: ', socket.connected);
    console.log('onTimeSlot method data in socket: ', data);
    setCourtData(data);
    setSelectedSlot((prev) => {
      if (prev?.timeSlot?._id) {
        const pass = data?.onTheCourt?.filter(
          (item) => item?.timeSlot?._id === prev?.timeSlot?._id,
        );
        return pass[0];
      }
      return data?.onTheCourt[0];
    });
  };

  const getCourtData = () => {
    console.log('court?.info', court);
    setLoading(true);
    formik.setFieldValue('title', court?.title);
    formik.setFieldValue('subtitle', court?.subTitle);
    formik.setFieldValue('info', court?.info);
    setLoading(false);
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('info', html);
    // If the editor is changed, consider it as editing and enable the form for update
    if (!isEditable) {
      setIsEditable(true);
      setButtonValue('Update');
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter court title'),
    subtitle: Yup.string().required('Please enter court subtitle'),
    info: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      info: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   if (!file) {
      //     setFileError("Please select image for court");
      //     return;
      //   }
      console.log('values: ', values);
      const user = JSON.parse(getUser());
      const data = {
        title: values.title,
        subTitle: values.subtitle,
        image: url !== null && url !== undefined ? url : court?.image,
        info: values.info,
      };

      console.log('data', data);
      fetchDataFromAPI(
        `Court/update?id=${court?._id}`,
        'post',
        data,
        user?.authToken,
      )
        .then((res) => {
          toast.success('Court updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          navigate('/courts');
          console.log('update court res: ', res?.data);
        })
        .catch((error) => {
          toast.error('Error while updating Court', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating court: ', error);
        });
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue('Update');
  };

  const handleFileChange = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append('image', image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI('upload', 'post', data, user?.authToken)
      .then((res) => {
        console.log('res: ', res?.data);
        setURL(res?.data);
        setImgURL(res?.data);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const selectSlot = (item) => {
    setSelectedSlot(item);
  };

  return (
    <div className="dsa__advice pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">Court Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={url ? url : court?.image}
            className="rounded-circle avatar"
            width={100}
            height={100}
          />
          {isEditable && (
            <div
              className="icon-style disabled"
              onClick={() => isEditable && fileInputRef?.current?.click()}>
              <i class="bi bi-pencil-square"></i> Edit
            </div>
          )}
          <input
            ref={fileInputRef}
            accept="image/*"
            className="d-none"
            type="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Title</label>
          <input
            type="text"
            name="title"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            // {...formik.getFieldProps("username")}
            value={formik.values.title}
            onChange={(e) => {
              formik.setFieldValue('title', e.target.value?.trimStart());
            }}
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-danger">{formik.errors.title}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Subtitle</label>
          <input
            type="text"
            name="subtitle"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black type_password"
            // {...formik.getFieldProps("email")}
            value={formik.values.subtitle}
            onChange={(e) => {
              formik.setFieldValue('subtitle', e.target.value?.trimStart());
            }}
          />
          {formik.touched.subtitle && formik.errors.subtitle && (
            <div className="text-danger">{formik.errors.subtitle}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Info</label>
          <ReactQuill
            theme="snow"
            value={formik.values.info}
            onChange={handleEditorChange}
            style={{height: 200}}
            // onBlur={formik.handleBlur}

            // style={{height: 200}}
          />
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}>
            Back
          </button>
          <button
            className="form_button btn advice__category"
            disabled={
              buttonValue === 'Update' &&
              formik.values.title === court?.title &&
              formik.values.subtitle === court?.subTitle &&
              formik.values.info === court.info &&
              url === ''
            }
            onClick={
              buttonValue === 'Update' ? formik.handleSubmit : enableEditing
            }>
            {buttonValue}
          </button>
        </div>

        {/* timeslots and users */}

        {/* users for particular timeslot */}
        <div>
          <label className="form-label fw-bold h4">Players</label>
          {selectedSlot && (
            <div className="row align-items-center mt-5 justify-content-center">
              {selectedSlot?.users?.length > 0 ? (
                selectedSlot?.users?.map((user, index) => (
                  <div
                    key={user?._id}
                    className="d-flex flex-column align-items-center col-sm-4 col-md-3 col-lg-3 m-1">
                    <img
                      src={user?.profile}
                      alt="user-picture"
                      className="rounded-circle"
                      width={70}
                      height={70}
                    />
                    <span className="h6 mt-2">{user?.username}</span>
                  </div>
                ))
              ) : (
                <p className="h2 text-center">No users have joined.</p>
              )}
            </div>
          )}
        </div>

        {/* timeslot listing */}
        <div className="row align-items-center mt-5 justify-content-center">
          <label className="form-label fw-bold h4">Game Time</label>
          {courtData &&
            courtData?.onTheCourt?.map((timeSlot, index) => (
              <div
                className={
                  selectedSlot?.timeSlot?._id === timeSlot?.timeSlot?._id
                    ? 'card-border m-2 card col-sm-5 col-md-4 col-lg-3 align-items-center py-2 px-1 rounded'
                    : 'border-0 m-2 card col-sm-5 col-md-4 col-lg-3 align-items-center py-2 px-1 rounded'
                }
                role="button"
                onClick={() => selectSlot(timeSlot)}>
                <img
                  src={
                    timeSlot?.users?.length === 0
                      ? zero
                      : timeSlot?.users?.length === 1
                      ? one
                      : timeSlot?.users?.length === 2
                      ? two
                      : 3 <= timeSlot?.users?.length <= 5
                      ? threeToFive
                      : 6 <= timeSlot?.users?.length <= 9
                      ? sixToNine
                      : timeSlot?.users?.length >= 10
                      ? tenPlus
                      : tenPlus
                  }
                  alt="player-count-icon"
                  className="mt-3"
                />
                <span className="mt-3">
                  {timeSlot?.users?.length + ' players'}
                </span>
                <span className="mt-2 mb-3">
                  {timeSlot?.timeSlot?.start_time +
                    ' - ' +
                    timeSlot?.timeSlot?.end_time}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CourtDetail;
