import {useFormik} from 'formik';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {getUser} from '../../utils/constants';
import ReactQuill from 'react-quill';

const AddCourtModal = (props) => {
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [imgURL, setImgURL] = useState('');
  const [inputKey, setInputKey] = useState('');
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter court title'),
    subtitle: Yup.string().required('Please enter court subtitle'),
    info: Yup.string().required('Please enter court info'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      info: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!file) {
        setFileError('Please select image for court');
        return;
      }
      console.log('values: ', values);
      const data = {
        title: values.title,
        subTitle: values.subtitle,
        info: values.info,
        image: imgURL,
      };
      const user = JSON.parse(getUser());
      fetchDataFromAPI('Court', 'post', data, user?.authToken)
        .then((res) => {
          toast.success('Court created successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('add court res: ', res?.data);
          props?.onClose();
          resetForm();
        })
        .catch((error) => {
          toast.error('Error while creating Court', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('add court error: ', error);
        });
    },
  });

  function functionThatResetsTheFileInput() {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  }

  const resetForm = () => {
    formik.resetForm();
    setFile(null);
    setFileError('');
    functionThatResetsTheFileInput();
    setImgURL('');
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('info', html);
  };

  return (
    <>
      <Modal
        onHide={() => {
          props?.onHide();
          resetForm();
        }}
        {...props}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Court</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Title</label>
            <input
              type="text"
              name="Title"
              placeholder="Enter Court Title"
              className="form form-control bg-transparent text-black add_inputs"
              // {...formik.getFieldProps("username")}
              value={formik.values.title}
              onChange={(e) => {
                formik.setFieldValue('title', e.target.value?.trimStart());
              }}
            />
            {formik.touched.title && formik.errors.title && (
              <div className="text-danger">{formik.errors.title}</div>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Subtitle</label>
            <input
              type="text"
              name="Subtitle"
              // {...formik.getFieldProps("email")}
              value={formik.values.subtitle}
              onChange={(e) => {
                formik.setFieldValue('subtitle', e.target.value?.trimStart());
              }}
              placeholder="Enter Court Subtitle"
              className="form form-control bg-transparent text-black add_inputs"
            />
            {formik.touched.subtitle && formik.errors.subtitle && (
              <div className="text-danger">{formik.errors.subtitle}</div>
            )}
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="category_label">Info</label>
            <ReactQuill
              theme="snow"
              value={formik.values.info}
              onChange={handleEditorChange}
              style={{height: 100}}
              // onBlur={formik.handleBlur}

              // style={{height: 200}}
            />

            {formik.touched.info && formik.errors.info && (
              <div className="text-danger mt-5">{formik.errors.info}</div>
            )}
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="category_label mt-4">Image</label>
            <input
              key={inputKey || ''}
              type="file"
              accept="image/*"
              name="image"
              placeholder="Pick Image for Court"
              className="form form-control bg-transparent text-black add_inputs"
              // {...formik.getFieldProps("password")}
              //   value={file}
              onChange={(e) => {
                setFile(e.target.files[0]);
                const image = e?.target?.files[0];
                const data = new FormData();
                data.append('image', image);
                const user = JSON.parse(getUser());
                fetchDataFromAPI('upload', 'post', data, user?.authToken)
                  .then((res) => {
                    console.log('res: ', res?.data);
                    setImgURL(res?.data);
                  })
                  .catch((error) => {
                    console.log('error: ', error);
                  });
                setFileError('');
              }}
            />
            {fileError && <div className="text-danger">{fileError}</div>}
          </div>
          {file && (
            <img
              src={imgURL}
              alt="court-image"
              className="rounded mt-3"
              width={50}
              height={50}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <button
            variant="secondary"
            className="btn btn-primary"
            //   data-bs-dismiss="modal"
            onClick={resetForm}>
            Clear
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="btn secondary_bg advice__category text-white">
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCourtModal;
