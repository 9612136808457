import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import { toast } from "react-toastify";

const MemberDetail = () => {
  const { state } = useLocation();
  const { user } = state;
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [showPassword, setShowPassword] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");
  const navigate = useNavigate();
  const fileInputRef = useRef();

  useEffect(() => {
    getLevels();
    getCourtData();
  }, []);

  const getLevels = () => {
    const theUser = JSON.parse(getUser());
    fetchDataFromAPI("level", "get", "", theUser?.authToken)
      .then((res) => {
        console.log("levels res: ", res?.data);
        setLevels(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("levels error: ", error);
      });
  };

  const getCourtData = () => {
    setLoading(true);
    formik.setFieldValue("username", user?.username);
    formik.setFieldValue("email", user?.email);
    formik.setFieldValue("level", user?.level?._id);
    formik.setFieldValue("signature_move", user?.signature_move);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please enter username"),
    level: Yup.string().required("Please select level"),
    signature_move: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      level: "",
      signature_move: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   if (!file) {
      //     setFileError("Please select image for court");
      //     return;
      //   }
      console.log("user update body: ", values);
      const admin = JSON.parse(getUser());
      const data = {
        id: user?._id,
        username: values.username,
        level: values.level,
        signature_move: values.signature_move,
        profile: url,
      };
      if (!url) {
        delete data.profile;
      }
      console.log("data: ", data);
      fetchDataFromAPI(`profile-update`, "post", data, admin?.authToken)
        .then((res) => {
          toast.success("Member updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          navigate("/members");
          console.log("update member res: ", res?.data);
        })
        .catch((error) => {
          toast.error("Error while updating Member", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating member: ", error);
        });
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  const handleFileChange = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append("image", image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("upload", "post", data, user?.authToken)
      .then((res) => {
        console.log("res: ", res?.data);
        setURL(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  return (
    <div className="dsa__advice pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">Member Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={url ? url : user?.profile}
            className="rounded-circle avatar"
            width={100}
            height={100}
          />
          {isEditable && (
            <div
              className="icon-style disabled"
              onClick={() => isEditable && fileInputRef?.current?.click()}
            >
              <i class="bi bi-pencil-square"></i> Edit
            </div>
          )}
          <input
            ref={fileInputRef}
            accept="image/*"
            className="d-none"
            type="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Username</label>
          <input
            type="text"
            name="username"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            // {...formik.getFieldProps("username")}
            value={formik.values.username}
            onChange={(e) => {
              formik.setFieldValue("username", e.target.value?.trimStart());
            }}
          />
          {formik.touched.username && formik.errors.username && (
            <div className="text-danger">{formik.errors.username}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Email</label>
          <input
            type="text"
            name="email"
            disabled={true}
            className="form form-control bg-transparent text-black type_password"
            value={formik.values.email}
          />
          {/* {formik.touched.subtitle && formik.errors.subtitle && (
            <div className="text-danger">{formik.errors.subtitle}</div>
          )} */}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Levels</label>
          <select
            className="form-select"
            name="level"
            disabled={!isEditable}
            {...formik.getFieldProps("level")}
          >
            {levels?.map((level, index) => (
              <option key={level?._id} value={level?._id}>
                {level?.level_name}
              </option>
            ))}
          </select>
          {formik.touched.level && formik.errors.level && (
            <div className="text-danger">{formik.errors.level}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Signature Move</label>
          <input
            type="text"
            name="signature_move"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("signature_move")}
            // value={formik.values.username}
            // onChange={(e) => {
            //   formik.setFieldValue("username", e.target.value?.trimStart());
            // }}
          />
          {formik.touched.signature_move && formik.errors.signature_move && (
            <div className="text-danger">{formik.errors.signature_move}</div>
          )}
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button btn advice__category"
            disabled={
              buttonValue === "Update" &&
              formik.values.username === user?.username &&
              formik.values.level === user?.level &&
              formik.values.signature_move === user?.signature_move &&
              url === ""
            }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MemberDetail;
