import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import AddCourtModal from "../../components/AddCourtModal/AddCourtModal";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";

const Courts = () => {
  const [loading, setLoading] = useState(false);
  const [courts, setCourts] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  useEffect(() => {
    getAllCourts();
  }, []);

  const getAllCourts = async () => {
    setLoading(true);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("Court", "get", "", user?.authToken)
      .then((res) => {
        console.log("courts: ", res?.data);
        setCourts(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("courts error: ", error);
      });
  };

  const onClose = () => {
    setModalShow(false);
    getAllCourts();
  };

  const onDeleteCourt = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI(`Court?id=${deleteID}`, "delete", "", user?.authToken)
      .then((res) => {
        toast.success("Court deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getAllCourts();
        handleClose();
        console.log("court delete res: ", res?.data);
      })
      .catch((error) => {
        toast.error("Error while deleting Court", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("court delete error: ", error);
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Courts</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Create Court
          </button>
        </div>
        <AddCourtModal
          onClose={onClose}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        {/* table here */}
        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Subtitle</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {courts?.length > 0 ? (
                courts?.map((court) => (
                  <tr key={court?._id}>
                    <td className="col-2">
                      <img
                        src={court?.image || logo}
                        alt="court-pic"
                        className="court_image"
                      />
                    </td>
                    <td className="col-2">
                      <p>{court?.title}</p>
                    </td>
                    <td className="col-4">
                      <p className="note">{court?.subTitle || "-"}</p>
                    </td>
                    <td className="col-2 text-end align-middle h-100">
                      <div className="d-flex gap-2  justify-content-end">
                        <span
                          onClick={() =>
                            navigate(`/courts/${court?._id}`, {
                              state: { court: court },
                            })
                          }
                        >
                          <BsFillEyeFill />
                        </span>
                        {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                        <div
                          className="delete_button"
                          onClick={() => {
                            setDeleteID(court?._id);
                            handleShow();
                          }}
                        >
                          <Delete />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete this Court?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteCourt}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Courts;
