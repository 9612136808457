import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import AddLevelModal from "../../components/AddLevelModal/AddLevelModal";
import { useFormik } from "formik";
import * as Yup from "yup";

const Levels = () => {
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [updateID, setUpdateID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  useEffect(() => {
    getLevels();
  }, []);

  const formik = useFormik({
    initialValues: {
      level_name: "",
    },
    validationSchema: Yup.object().shape({
      level_name: Yup.string().required("Please enter level name"),
    }),
    onSubmit: (values) => {
      console.log("values: ", values);
      const data = {
        id: updateID,
        level_name: values.level_name,
      };
      const user = JSON.parse(getUser());
      fetchDataFromAPI("level", "post", data, user?.authToken)
        .then((res) => {
          console.log("update level res: ", res);
          toast.success("Level updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          getLevels();
          setUpdateModalShow(false);
          formik.resetForm();
        })
        .catch((error) => {
          toast.error("Error while updating Level", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("update level error: ", error);
        });
    },
  });

  const getLevels = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI("level", "get", "", user?.authToken)
      .then((res) => {
        console.log("levels res: ", res?.data);
        setLevels(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("levels error: ", error);
      });
  };

  const onClose = () => {
    setModalShow(false);
    getLevels();
  };

  const onDeleteLevel = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI(`level?id=${deleteID}`, "delete", "", user?.authToken)
      .then((res) => {
        toast.success("Level deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getLevels();
        handleClose();
        console.log("Level delete res: ", res?.data);
      })
      .catch((error) => {
        toast.error("Error while deleting Level", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("Level delete error: ", error);
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Levels</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Create Level
          </button>
        </div>
        <AddLevelModal
          onClose={onClose}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        {/* table here */}
        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th className="col-4 text-center">No.</th>
                <th className="col-4 text-center">Level Name</th>
                <th className="col-4 text-center">Actions</th>
              </tr>
            </thead>

            <tbody>
              {levels?.length > 0 ? (
                levels?.map((level, index) => (
                  <tr key={level?._id}>
                    <td className="col-4 text-center">
                      <p>{index + 1}</p>
                    </td>
                    <td className="col-4 text-center">
                      <span className="badge bg-info">{level?.level_name}</span>
                    </td>
                    <td className="col-4 text-center h-100">
                      <div className="d-flex gap-2 justify-content-center">
                        <span
                          onClick={() => {
                            // navigate(`/levels/${level?._id}`, {
                            //   state: { level: level },
                            // })
                            formik.setFieldValue(
                              "level_name",
                              level?.level_name
                            );
                            setUpdateID(level?._id);
                            setUpdateModalShow(true);
                          }}
                        >
                          <BsFillEyeFill />
                        </span>
                        {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                        <div
                          className="delete_button"
                          onClick={() => {
                            setDeleteID(level?._id);
                            handleShow();
                          }}
                        >
                          <Delete />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete this Level?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteLevel}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update level modal */}
      <Modal
        show={updateModalShow}
        centered
        onHide={() => setUpdateModalShow(false)}
      >
        <Modal.Header
          closeButton
          // closeVariant="white"
          // className="primary_bg border-0 text-white"
        >
          <Modal.Title>Update Level</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Level Name</label>
            <input
              type="text"
              name="level_name"
              placeholder="Enter level name"
              className="form form-control bg-transparent text-black add_inputs"
              // {...formik.getFieldProps("username")}
              value={formik.values.level_name}
              onChange={(e) => {
                formik.setFieldValue("level_name", e.target.value?.trimStart());
              }}
            />
            {formik.touched.level_name && formik.errors.level_name && (
              <div className="text-danger">{formik.errors.level_name}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
        // className="primary_bg border-0"
        >
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="primary_bg text-white border-0"
            onClick={formik.handleSubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Levels;
