import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../../utils/constants";
import { Button } from "react-bootstrap";

const LogOutModal = () => {
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const logout = async () => {
    localStorage.removeItem(constants.USER);
    navigate("/login");
    window.location.reload();
  };

  return (
    <div
      id="userModal5"
      className="modal modal-content-scrollable"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-header text-black border-0">
            <h5 className="modal-title">Logout Confirmation</h5>
          </div>
          <div className="modal-body">
            <p className="primary_bg bg-white text-black">
              Are you sure you want to Logout?
            </p>
          </div>
          <div className="modal-footer border-0 ">
            <Button
              variant="secondary"
              onClick={handleClose}
              type="button"
              data-bs-dismiss="modal"
              id="close__button"
            >
              Close
            </Button>
            <Button variant="danger" onClick={logout}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOutModal;
