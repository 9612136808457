import AdminDetail from "../pages/AdminDetails/AdminDetails";
import Admins from "../pages/Admins/Admins";
import CourtDetail from "../pages/CourtDetail/CourtDetail";
import Courts from "../pages/Courts/Courts";
import Dashboard from "../pages/Dashboard/Dashboard";
import Levels from "../pages/Levels/Levels";
import MemberDetail from "../pages/MemberDetail/MemberDetail";
import Members from "../pages/Members/Members";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import TimeSlots from "../pages/TimeSlots/TimeSlots";
import PrivateRoute from "./RouteProtection";

const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/courts",
    element: (
      <PrivateRoute>
        <Courts />
      </PrivateRoute>
    ),
  },
  {
    path: "/courts/:id",
    element: (
      <PrivateRoute>
        <CourtDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/members",
    element: (
      <PrivateRoute>
        <Members />
      </PrivateRoute>
    ),
  },
  {
    path: "/members/:id",
    element: (
      <PrivateRoute>
        <MemberDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/admins",
    element: (
      <PrivateRoute>
        <Admins />
      </PrivateRoute>
    ),
  },
  {
    path: "/admins/:id",
    element: (
      <PrivateRoute>
        <AdminDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/levels",
    element: (
      <PrivateRoute>
        <Levels />
      </PrivateRoute>
    ),
  },
  {
    path: "/timeslots",
    element: (
      <PrivateRoute>
        <TimeSlots />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/terms-conditions",
  //   element: <TermsAndConditions />,
  // },
];

export default routes;
